import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { useState } from "react";
import classNames from "classnames";

export const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuClick = () => {
        setShowMenu(!showMenu);
    }

    return (
        <>
            <div className="kf-top-bar" role="presentation" />
            <header className="kf-header text-light text-center text-light">
                <nav className={classNames('container pt-5 d-flex justify-content-between align-items-center', { 'mb-5': !showMenu, 'mb-4': showMenu })}>
                    <Link to="/">
                        <img src="/images/logo.svg" alt="KegFinder" className="kf-header__logo" title="KegFinder" />
                    </Link>
                    <ButtonGroup className="d-none d-sm-block">
                        <LinkContainer to={"/contact"}>
                            <Button variant="light" className="me-3" title="Contact us">
                                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            </Button>
                        </LinkContainer>
                        <LinkContainer to={"/submit-retailer"}>
                            <Button variant="light">
                                <FontAwesomeIcon icon={faArrowUpFromBracket} size="lg" className="me-2" />
                                Submit retailer</Button>
                        </LinkContainer>
                    </ButtonGroup>
                    <Button variant="light" className="d-block d-sm-none" onClick={handleMenuClick} aria-label={!showMenu ? 'Expand menu' : 'Collapse menu'}>
                        <FontAwesomeIcon icon={faBars} title="Menu" size="lg" />
                    </Button>
                </nav>
                {showMenu && (
                    <nav className="container d-block d-sm-none mb-4" aria-hidden={!showMenu}>
                        <LinkContainer to={"/contact"}>
                            <Button variant="light" className="d-block my-3" title="Contact us">
                                Contact us
                            </Button>
                        </LinkContainer>
                        <LinkContainer to={"/submit-retailer"}>
                            <Button variant="light" className="d-block my-3" title="Submit retailer">Submit retailer</Button>
                        </LinkContainer>
                    </nav>
                )}
            </header>
        </>
    );
}