import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import { MainLayout } from "../layouts/mainLayout";

export const Error = () => {
    return (
        <MainLayout title="Error">
            <Container>
                <h1 className="display-4">Oops, we spilt our pint!</h1>
                <p className="lead">Sorry, an unexpected error has occurred.</p>
                <LinkContainer to="/">
                    <Button variant="primary">&larr; Return to homepage</Button>
                </LinkContainer>
            </Container>
        </MainLayout>
    );
};