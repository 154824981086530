import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { MainLayout } from "../layouts/mainLayout";
import { ContactFormBindingModel, ValidationProblemDetails } from "../apiClient";
import { useApiClient } from "../helpers/useApiClient";
import { ApiError } from "../components/apiError";
import { RecaptchaDisclaimer } from "../components/recaptchaDisclaimer";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string().email("Please enter a valid email").required("Please enter your email address, so we can get back to you"),
    message: Yup.string().required("Please enter a message"),
});

export const Contact = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const apiClient = useApiClient();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<Error | ValidationProblemDetails | null>(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        validationSchema,
        onSubmit: async () => {
            setError(null);
            setSuccess(false);
            try {
                await apiClient.contactForm(new ContactFormBindingModel({
                    name: formik.values.name,
                    email: formik.values.email,
                    message: formik.values.message,
                    captcha: await executeRecaptcha!('contact'),
                }));
                setSuccess(true);
                formik.resetForm();
            } catch (error) {
                console.error(error);
                if (error instanceof ValidationProblemDetails || error instanceof Error) {
                    return setError(error);
                }
            }
        },
    });

    return (
        <MainLayout loading={formik.isSubmitting} title="Contact">
            <Container>
                <ApiError error={error} />
                {success && (
                    <Alert variant="success" dismissible>
                        <Alert.Heading>Thanks for getting in touch!</Alert.Heading>
                        We'll get back to you as soon as we can.
                    </Alert>
                )}

                <h1 className="display-4">Contact us.</h1>
                <p className="lead">We'd love to hear from you! Please note, we don't sell kegs ourselves so don't bother asking!</p>

                <Form onSubmit={formik.handleSubmit}>
                    <Stack gap={3}>
                        <Form.Group controlId="name">
                            <Form.Label>Your name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                required
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!(formik.touched.name && formik.errors.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Your email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                name="email"
                                required
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!(formik.touched.email && formik.errors.email)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="message">
                            <Form.Label>Your message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Enter your message"
                                name="message"
                                required
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!(formik.touched.message && formik.errors.message)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <RecaptchaDisclaimer />

                        <Button variant="primary" type="submit" size="lg">
                            Submit
                        </Button>
                    </Stack>
                </Form>
            </Container>
        </MainLayout>
    );
};