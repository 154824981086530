import { Button, Stack } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { GoogleMapsButton } from "./googleMapsButton";
import { Map } from "./map";
import { LocationViewModel } from "../apiClient";
import { formatPostcode } from "../helpers/postcodeHelper";

export const LocationResult = (props: { location: LocationViewModel }) => {
    const { location } = props;
    return (
        <Card className="kf-location-result">
            <Card.Body>
                <Row>
                    <Col lg={5} className="kf-location-result__map mb-3 mb-lg-0">
                        <Map latitude={location.coords!.latitude!} longitude={location.coords!.longitude!} />
                    </Col>
                    <Col lg={7}>
                        <h2>{location.name}</h2>
                        <p className="lead">{formatPostcode(location.postcode!)}</p>
                        <p><strong>Machines sold for:</strong> {location.machines!.map(m => m.name).join(', ')}</p>
                        <p><strong>Last updated:</strong> {location.updatedDate!.toLocaleDateString()}</p>
                        <Stack gap={3}>
                            {location.comment && <div className="border p-3">{location.comment}</div>}
                            <div>
                                <GoogleMapsButton className="me-2" latitude={location.coords!.latitude!} longitude={location.coords!.longitude!} />
                                <LinkContainer to={`/report/${location.id}`}>
                                    <Button variant="danger">Report an issue</Button>
                                </LinkContainer>
                            </div>
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}