import { MapContainer, Marker, TileLayer } from 'react-leaflet'


export const Map = (props: { latitude: number, longitude: number }) => (
    <MapContainer center={[props.latitude, props.longitude]} zoom={9} scrollWheelZoom={false} dragging={false} tap={false} doubleClickZoom={false} zoomControl={false} style={{ height: "250px" }}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[props.latitude, props.longitude]}>
        </Marker>
    </MapContainer>
);