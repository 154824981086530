import { Spinner } from 'react-bootstrap';

const AppSpinner = () => {
    return (
        <div className="kf-spinner text-center">
            <Spinner animation="border" variant="light" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default AppSpinner;
