import { ReactNode } from 'react';
import { Helmet } from "react-helmet";

import { Header } from '../components/header';
import { Footer } from '../components/footer';
import AppSpinner from '../components/appSpinner';
import { ScrollToTop } from '../components/scrollToTop';

type MainLayoutProps = {
    children: ReactNode;
    loading?: boolean;
    title?: string;
}

const title = 'KegFinder';

export const MainLayout = (props: MainLayoutProps) => (
    <>
        <ScrollToTop />
        <Helmet>
            <title>{props.title ? `${props.title} - ${title}` : title}</title>
        </Helmet>
        <div className="kf-content flex-grow-1">
            <Header></Header>
            <main>
                {props.loading && <AppSpinner />}
                {!props.loading && props.children}
            </main>
        </div>
        <Footer />
    </>
);
