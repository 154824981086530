import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import { MainLayout } from "../layouts/mainLayout";
import { RegexConstants } from "../constants";
import { useApiClient } from "../helpers/useApiClient";
import { CoordinatesBindingModel } from "../apiClient";
import { Alert } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  postcode: Yup.string().matches(RegexConstants.Postcode, "Invalid UK postcode").required("Valid postcode is required"),
});

export const Home = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [failedNearestPostcode, setFailedNearestPostcode] = useState(false);

  const formik = useFormik({
    initialValues: {
      postcode: "",
    },
    validationSchema,
    onSubmit: () => {
      formik.setSubmitting(true);
      navigate(`/results/${formik.values.postcode}`);
    },
  });
  const setValues = formik.setValues;

  const getNearestPostcode = useCallback(async (latitude: number, longitude: number) => {
    try {
      const response = await apiClient.nearest(new CoordinatesBindingModel({ latitude, longitude }));
      setValues({ postcode: response.postcode! })
    } catch {
      setFailedNearestPostcode(true);
    }
  }, [apiClient, setValues, setFailedNearestPostcode]);

  useEffect(() => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(pos => {
      getNearestPostcode(pos.coords.latitude, pos.coords.longitude);
    }, () => {
      setFailedNearestPostcode(true);
    });
  }, [getNearestPostcode, setFailedNearestPostcode]);

  return (
    <MainLayout title="Home">
      <Container className="text-center">
        <div className="p-2 p-lg-5">
          <h1 className="display-4">Find beer &amp; cider kegs in the UK near you.</h1>
          <p className="lead mb-4">
            Community-sourced data about retailers in your area that sell Blade&reg;, TORPS&reg;, PerfectDraft&reg; and others
          </p>
          {failedNearestPostcode &&
            <Alert>
              Unable to automatically detect postcode. Please enter manually below.
            </Alert>}
          <Form onSubmit={formik.handleSubmit}>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Form.Group controlId="postcode">
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="text-center"
                      size="lg"
                      name="postcode"
                      placeholder="Your postcode"
                      aria-label="Your postcode"
                      aria-describedby="postcode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postcode}
                      isInvalid={!!(formik.touched.postcode && formik.errors.postcode)}
                      required
                    />
                    <Form.Control.Feedback type="invalid" className="sr-only">
                      {formik.errors.postcode}
                    </Form.Control.Feedback>
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      <FontAwesomeIcon icon={faSearch} title="Search" size="lg" />
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </MainLayout>
  );
};
