import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { formatPostcode } from "../helpers/postcodeHelper";
import { MainLayout } from "../layouts/mainLayout";
import { LocationViewModel, MachineViewModel, SearchLocationsBindingModel } from "../apiClient";
import { useApiClient } from "../helpers/useApiClient";
import { LocationResult } from "../components/locationResult";
import AppSpinner from "../components/appSpinner";
import { Error } from "../routes/error";

type ResultsRouteParams = {
    postcode: string;
};

export const Results = () => {
    const [error, setError] = useState<boolean>(false);
    const [machines, setMachines] = useState<MachineViewModel[] | null>(null);
    const [locations, setLocations] = useState<LocationViewModel[] | null>(null);
    const params = useParams<ResultsRouteParams>();
    const apiClient = useApiClient();

    const { handleChange, handleSubmit, values, setSubmitting, isSubmitting, submitForm } = useFormik({
        initialValues: {
            postcode: formatPostcode(params.postcode),
            machines: [] as string[],
            query: "",
        },
        onSubmit: async (values) => {
            try {
                const searchResults = await apiClient.search(new SearchLocationsBindingModel({
                    postcode: values.postcode,
                    machines: values.machines,
                    query: values.query,
                }));
                setLocations(searchResults.results!);
                setSubmitting(false);
            } catch (error) {
                console.error(error);
                setError(true);
            }
        },
    });

    useEffect(() => {
        const loadMachines = async () => {
            setSubmitting(true);
            setMachines(await apiClient.machines());
            submitForm();
        };

        if (machines) return;
        loadMachines();
    }, [apiClient, machines, setSubmitting, submitForm]);

    return (
        <>
            {error && <Error />}
            {!error &&
                <MainLayout title={`Search results for ${values.postcode}`}>
                    <Form onSubmit={handleSubmit}>
                        <Container>
                            <div className="mb-3">
                                &larr; <Link to="/">Back to home</Link>
                            </div>

                            <Stack gap={3}>
                                <div>
                                    <h1>Search results for {values.postcode}.</h1>
                                    {locations && <p className="lead">We found {locations?.length} locations within 50 miles of you.</p>}
                                </div>

                                <Row className="g-4">
                                    <Col lg={2}>
                                        <Stack gap={3}>
                                            {machines && (
                                                <Form.Group id="machines">
                                                    <Form.Label>Machine</Form.Label>
                                                    {machines.map(machine => (
                                                        <Form.Check
                                                            key={machine.id}
                                                            type="checkbox"
                                                            name="machines"
                                                            id={`machine-${machine.id}`}
                                                            label={machine.name}
                                                            value={machine.id}
                                                            checked={values.machines?.includes(machine.id!) ?? false}
                                                            onChange={ev => {
                                                                handleChange(ev);
                                                                submitForm();
                                                            }}
                                                        />
                                                    ))}
                                                </Form.Group>
                                            )}

                                            <Form.Group>
                                                <Form.Label>Search</Form.Label>
                                                <Form.Control type="text" name="query" placeholder="Search terms" onChange={handleChange} value={values.query} />
                                            </Form.Group>

                                            <Form.Group>
                                                <Button variant="primary" type="submit" disabled={isSubmitting}>Search</Button>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col lg={10}>
                                        {(locations && !isSubmitting) && (
                                            <>
                                                {locations?.length === 0 &&
                                                    <Alert variant="danger">
                                                        <Alert.Heading>No retailers found you matching your search criteria.</Alert.Heading>
                                                        If you know of one, why not <Link to="/submit-retailer">submit it?</Link>
                                                    </Alert>
                                                }
                                                <Stack gap={3}>
                                                    {locations?.map(location => (
                                                        <LocationResult location={location} key={location.id} />
                                                    ))}
                                                </Stack>
                                            </>
                                        )}
                                        {(!locations || isSubmitting) && (
                                            <AppSpinner />
                                        )}
                                    </Col>
                                </Row>
                            </Stack>
                        </Container>
                    </Form>
                </MainLayout>
            }
        </>
    );
};