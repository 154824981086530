import Alert from "react-bootstrap/Alert";
import { ValidationProblemDetails } from "../apiClient";

export const ApiError = ({ error }: { error: Error | ValidationProblemDetails | null }) => {
    if (!error) return null;

    return (
        <Alert variant="danger">
            {error instanceof ValidationProblemDetails && error.errors && (
                <>
                    <Alert.Heading>One or more validation errors occurred</Alert.Heading>
                    <ul className="mb-0">
                        {Object.keys(error.errors).map((key, index) => (
                            <li key={index}>{(error as unknown as ValidationProblemDetails).errors![key]}</li>
                        ))}
                    </ul>
                </>
            )}
            {!(error instanceof ValidationProblemDetails) && (
                <>
                    <Alert.Heading>An unexpected error occurred</Alert.Heading>
                    <p className="mb-0">{error.message}</p>
                </>
            )}
        </Alert>
    );
}