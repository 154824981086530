import Button from "react-bootstrap/Button";

export const GoogleMapsButton = (props: { className?: string, latitude: number, longitude: number }) => (
    <Button
        className={props.className}
        variant="primary"
        href={`https://www.google.com/maps/search/?api=1&query=${props.latitude},${props.longitude}`}
        target="_blank"
        rel="noopener noreferrer">
        View on Google Maps
    </Button>
)
