import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/esm/Stack";
import { LinkContainer } from 'react-router-bootstrap';

import { MainLayout } from "../layouts/mainLayout"
import { useApiClient } from "../helpers/useApiClient";
import { formatPostcode } from "../helpers/postcodeHelper";
import { LocationViewModel, ReportLocationBindingModel, ValidationProblemDetails } from "../apiClient";
import { ApiError } from "../components/apiError";
import { RecaptchaDisclaimer } from "../components/recaptchaDisclaimer";

type ReportRouteParams = {
    id: string;
};

const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string().email("Please enter a valid email").required("Please enter your email address, so we can get back to you"),
    message: Yup.string().required("Please enter a message"),
});

export const Report = () => {
    const apiClient = useApiClient();
    const locationId = useParams<ReportRouteParams>().id;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<Error | ValidationProblemDetails | null>(null);
    const [location, setLocation] = useState<LocationViewModel | null>(null);
    const [loading, setLoading] = useState(true);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        validationSchema,
        onSubmit: async () => {
            setError(null);
            setSuccess(false);
            try {
                await apiClient.report(location!.id!, new ReportLocationBindingModel({
                    name: formik.values.name,
                    email: formik.values.email,
                    message: formik.values.message,
                    captcha: await executeRecaptcha!('report'),
                }));

                setSuccess(true);
                formik.resetForm();
            } catch (error) {
                console.error(error);
                if (error instanceof ValidationProblemDetails || error instanceof Error) {
                    return setError(error);
                }
            }
        },
    });

    useEffect(() => {
        const loadData = async () => {
            if (!locationId) return;
            setLoading(true);
            const location = await apiClient.locationsGET(locationId);
            setLocation(location);
            setLoading(false);
        }

        loadData();
    }, [apiClient, locationId]);

    return (
        <MainLayout loading={loading || formik.isSubmitting} title="Report">
            <Container>
                <ApiError error={error} />

                {success && (
                    <>
                        <h1 className="display-4">Thank you for your report.</h1>
                        <p>
                            We'll review your report and take action if necessary.
                        </p>
                        <LinkContainer to={`/results/${location?.postcode}`}>
                            <Button variant="primary">&larr; Back to results</Button>
                        </LinkContainer>
                    </>
                )}

                {!success && (
                    <>
                        <div className="mb-3">
                            &larr; <a href={`/results/${location?.postcode}`}>Back to results</a>
                        </div>

                        <h1 className="display-4">Report location.</h1>
                        <p className="lead">{location?.name} ({formatPostcode(location?.postcode)})</p>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack gap={3}>
                                <Form.Group controlId="name">
                                    <Form.Label>Your name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        name="name"
                                        required
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!(formik.touched.name && formik.errors.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.Label>Your email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email address"
                                        name="email"
                                        required
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!(formik.touched.email && formik.errors.email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="message">
                                    <Form.Label>Your message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Enter your message"
                                        name="message"
                                        required
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={!!(formik.touched.message && formik.errors.message)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <RecaptchaDisclaimer />

                                <Button variant="primary" type="submit" size="lg">
                                    Submit report
                                </Button>
                            </Stack>
                        </Form>
                    </>
                )}
            </Container>
        </MainLayout>
    );
}