import { RegexConstants } from "../constants";

export const formatPostcode = (postcode?: string | null): string | null => {
    if (!postcode) return null;

    const postcodeMatch = postcode?.match(RegexConstants.Postcode);
    if (!postcodeMatch) {
        throw new Error(`Invalid postcode: ${postcode}`);
    }

    return `${postcodeMatch[1].toUpperCase()} ${postcodeMatch[2].toUpperCase()}`;
};