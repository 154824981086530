import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { CreateSubmissionBindingModel, MachineViewModel, ValidationProblemDetails } from "../apiClient";
import { useApiClient } from "../helpers/useApiClient";
import { MainLayout } from "../layouts/mainLayout";
import { RegexConstants } from "../constants";
import { ApiError } from "../components/apiError";
import { RecaptchaDisclaimer } from "../components/recaptchaDisclaimer";

const SubmitRetailerSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string().email("Please enter a valid email").required("Please enter your email address, so we can get back to you"),
    retailerName: Yup.string().required("Please enter the retailer name"),
    retailerPostcode: Yup.string().required("Please enter the retailer postcode").matches(RegexConstants.Postcode, "Invalid UK postcode"),
    comment: Yup.string(),
    machines: Yup.array().min(1, "Please select at least one machine"),
});

export const SubmitRetailer = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const apiClient = useApiClient();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<Error | ValidationProblemDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [machines, setMachines] = useState<MachineViewModel[] | null>(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            retailerName: '',
            retailerPostcode: '',
            comment: '',
            machines: [] as string[],
        },
        validationSchema: SubmitRetailerSchema,
        onSubmit: async (values) => {
            setError(null);
            setSuccess(false);
            try {
                await apiClient.submissionsPOST(new CreateSubmissionBindingModel({
                    name: values.name,
                    email: values.email,
                    retailerName: values.retailerName,
                    retailerPostcode: values.retailerPostcode,
                    comment: values.comment,
                    machines: values.machines,
                    captcha: await executeRecaptcha!('submitRetailer'),
                }));
                setSuccess(true);
                formik.resetForm();
            } catch (error) {
                console.error(error);
                if (error instanceof ValidationProblemDetails || error instanceof Error) {
                    setError(error);
                }
            }
        }
    });

    useEffect(() => {
        const loadData = async () => {
            setMachines(await apiClient.machines());
            setLoading(false);
        };
        loadData();
    }, [apiClient, setLoading, setMachines]);

    return (
        <MainLayout title="Submit retailer" loading={loading || formik.isSubmitting}>
            <Container>
                <Row>
                    <Col>
                        <ApiError error={error} />
                        {success && (
                            <Alert variant="success" dismissible>
                                <Alert.Heading>Thanks for submitting a retailer!</Alert.Heading>
                                We'll send you an email when it's been approved or be in touch if we need any more information. Feel free to submit another retailer in the meantime.
                            </Alert>
                        )}

                        <h1 className="display-4">Submit retailer.</h1>
                        <p className="lead">Help other home-draught beer lovers in your area!</p>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack gap={3}>
                                <Form.Group controlId="name">
                                    <Form.Label>Your name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        name="name"
                                        required
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                        isInvalid={!!(formik.touched.name && formik.errors.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.Label>Your email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        required
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                        isInvalid={!!(formik.touched.email && formik.errors.email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="retailerName">
                                    <Form.Label>Retailer name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter the retailer name"
                                        name="retailerName"
                                        required
                                        value={formik.values.retailerName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                        isInvalid={!!(formik.touched.retailerName && formik.errors.retailerName)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.retailerName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="retailerPostcode">
                                    <Form.Label>Retailer postcode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter the retailer postcode"
                                        name="retailerPostcode"
                                        required
                                        value={formik.values.retailerPostcode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                        isInvalid={!!(formik.touched.retailerPostcode && formik.errors.retailerPostcode)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.retailerPostcode}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="machines">
                                    <Form.Label>Machines kegs sold for</Form.Label>
                                    {machines && (
                                        <>
                                            {machines.map(machine => (
                                                <Form.Check
                                                    key={machine.id}
                                                    type="checkbox"
                                                    name="machines"
                                                    id={`machine-${machine.id}`}
                                                    label={machine.name}
                                                    value={machine.id}
                                                    checked={formik.values.machines?.includes(machine.id!) ?? false}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={formik.isSubmitting}
                                                    isInvalid={!!(formik.touched.machines && formik.errors.machines)}
                                                />
                                            ))}
                                        </>
                                    )}
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.machines}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="comment">
                                    <Form.Label>Any additional comments?</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Any additional comments?"
                                        name="comment"
                                        value={formik.values.comment}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                        isInvalid={!!(formik.touched.comment && formik.errors.comment)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.comment}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <RecaptchaDisclaimer />

                                <Button variant="primary" type="submit" size="lg" disabled={formik.isSubmitting}>
                                    Submit retailer
                                </Button>
                            </Stack>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    );
};