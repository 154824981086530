import './index.scss';

import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Home } from './routes/home';
import { Contact } from './routes/contact';
import { SubmitRetailer } from './routes/submitRetailer';
import { Results } from './routes/results';
import { Report } from './routes/report';
import { Error } from './routes/error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />
  },
  {
    path: '/results/:postcode',
    element: <Results />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: '/report/:id',
    element: <Report />
  },
  {
    path: "/submit-retailer",
    element: <SubmitRetailer />
  }
]);


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <GoogleReCaptchaProvider reCaptchaKey="6LcKYeAnAAAAAJUu1O79P6klEU5HxQA6g1loLFRp">
      <RouterProvider router={router} />
  </GoogleReCaptchaProvider>
)

